<template>
  <div class="supplierStepOneCmpt">
    <!-- 主体区域 -->
    <div class="facilityBox">
      <div class="tableData">
        <div class="title">运单信息进度</div>
        <!-- 步骤条 -->
        <Step :activeList="[1, 0, 0, 0]"></Step>
        <!-- 合计 -->
        <div class="settleBox">
          <el-row>
            <el-col :span="5"
              >运单数量(条)：<strong>{{ pagination1.total }}</strong></el-col
            >
            <el-col :span="5"
              >运费(元)：<strong>{{
                waybillListSum | formatMoney
              }}</strong></el-col
            >
            <el-col :span="5">服务费/税费(元)：</el-col>
            <el-col :span="9"
              >{{
                getWaybillType.sceneId == 7 ? "工单" : "运单"
              }}总金额(元)：</el-col
            >
          </el-row>
          <el-row>
            <el-col :span="5"
              >货主单位：<strong>{{
                settlementInfo.InvoiceTitleName
              }}</strong></el-col
            >
            <el-col :span="5"
              >货主税号：<strong>{{
                settlementInfo.InvoiceTitleCreditCode
              }}</strong></el-col
            >
            <el-col :span="5"
              >货主电话：<strong>{{
                settlementInfo.InvoiceTitlePhone
              }}</strong></el-col
            >
            <el-col :span="9"
              >货主地址：<strong>{{
                settlementInfo.InvoiceTitleAddress
              }}</strong></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="5"
              >货主账号：<strong>{{
                settlementInfo.InvoiceTitleBankerNumber
              }}</strong></el-col
            >
            <el-col :span="5">专票普票：<strong></strong></el-col>
            <el-col :span="5">支付方式：<strong></strong></el-col>
            <el-col :span="9"
              >货主开户行：<strong>{{
                settlementInfo.InvoiceTitleBankerName
              }}</strong></el-col
            >
          </el-row>
        </div>
        <!-- 表格信息 -->
        <el-table
          :data="tableData"
          v-loading="flag.loadingTable || flag.dialogLoading"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        >
          <el-table-column
            fixed
            align="center"
            prop="index"
            label="序号"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="WaybillNumber"
            label="运单号"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="CarriageBillID"
            label="客户单号"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="InvoiceType"
            label="发票类型"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="TaskType"
            label="业务类型"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="TaskSceneName"
            label="场景类型"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Shipper_name"
            label="货主单位"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="DriverName"
            :label="getWaybillType.sceneId == 7 ? '操作员姓名' : '司机姓名'"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Phone"
            :label="
              getWaybillType.sceneId == 7 ? '操作员手机号码' : '司机手机号码'
            "
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="DriverIDCard"
            :label="
              getWaybillType.sceneId == 7
                ? '操作员身份证号码'
                : '司机身份证号码'
            "
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openDerverInfo(scope.row,scope.row.DriverUserID)"
              >
                {{ scope.row.DriverIDCard }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="PayName"
            label="收款人姓名"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Recy_payment_idcard"
            label="收款人手机号码"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="PayeeIDCard"
            label="收款人身份证号码"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openDerverInfo(scope.row,scope.row.PayeeUserID)"
              >
                {{ scope.row.PayeeIDCard }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="LeaderName"
            label="车队长姓名"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="LeaderPhone"
            label="车队长手机号码"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="LeaderIDCard"
            label="车队长身份证号码"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openDerverInfo(scope.row,scope.row.LeaderUserID)"
              >
                {{ scope.row.LeaderIDCard }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="LicensePlate"
            label="工程设备名称"
            width="200"
            v-if="getWaybillType.sceneId == 7"
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openVehicleInfo(scope.row)"
              >
                {{ scope.row.LicensePlate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="LicensePlate"
            label="车牌"
            width="200"
            v-if="getWaybillType.sceneId != 7"
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openVehicleInfo(scope.row)"
              >
                {{ scope.row.LicensePlate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="Net_weight"
            label="装货净重(吨)"
            width="200"
            show-overflow-tooltip
            v-if="getWaybillType.sceneId != 7"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Product_name"
            label="货品名称"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Product_unit"
            label="货品单位"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Loading_time"
            label="装货时间"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Submission_time"
            label="签收时间"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Start_addrees"
            label="起始地"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openMap(scope.row)"
              >
                {{ scope.row.Start_addrees }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="Arrivals"
            label="到达地"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color: blue; cursor: pointer"
                @click="openMap(scope.row)"
              >
                {{ scope.row.Arrivals }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="Number_allocated_drivers"
            :label="
              getWaybillType.sceneId == 7 ? '操作员装货数量' : '司机装货数量'
            "
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Driver_transportation_price"
            :label="
              getWaybillType.sceneId == 7
                ? '操作员运输单价（人民币）'
                : '司机运输单价（人民币）'
            "
            width="200"
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.Driver_transportation_price | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="amount_count"
            :label="getWaybillType.sceneId == 7 ? '工单费' : '运费'"
            width="200"
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.amount_count | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="Taxes"
            label="服务费/税费"
            width="200"
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.Taxes | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="TotalAmount"
            :label="getWaybillType.sceneId == 7 ? '工单总金额' : '运单总金额'"
            width="200"
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.TotalAmount | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="Entrusted_Collection_Amount"
            label="委托代开运费"
            width="200"
            v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))"
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="EntrustedTaxes"
            label="委托代开服务费/税费"
            width="200"
            v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))"
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.EntrustedTaxes | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="EntrustedTotalAmount"
            label="委托代开运单总金额"
            width="200"
            v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))"
          >
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.EntrustedTaxes | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="Unloading_net_weight"
            label="卸货净重(吨)"
            width="200"
            show-overflow-tooltip
            v-if="getWaybillType.sceneId != 7"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="SourceBillId"
            label="溯源运单号"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="RiskPath"
            label="风控路径"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop=""
            label="支付方式"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="PayeeRemark"
            label="收款人备注"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <!-- <el-table-column
            align="center"
            prop=""
            label="开票金额"
            width="200"
            show-overflow-tooltip
          ></el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          style="margin-top: 16px"
          @current-change="handleCurrentChange1"
          :current-page.sync="pagination1.page"
          :page-size="pagination1.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination1.total"
        >
        </el-pagination>
        <!-- 右下角按钮组 -->
        <div style="text-align: center">
          <el-button
            type="primary"
            size="mini"
            @click="$router.push('/waybill/waybillManage/waybillRepository')"
            icon="el-icon-arrow-left"
            >返回</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="flag.settlement"
            @click="goStepTwo"
            >{{
              $entrustSceneIds.includes(Number(getWaybillType.sceneId))
                ? "代开发票申请"
                : "结算申请"
            }}</el-button
          >
        </div>
      </div>
    </div>
    <!-- 地图组件dialog -->
    <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body>
      <!-- 地图组件 -->
      <TXmap
        style="height: 600px"
        v-if="flag.showMap"
        ref="map"
        :list="mapList"
      ></TXmap>
    </el-dialog>
    <!-- 车辆信息dialog -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.showVehicleInfo"
      append-to-body
    >
      <VehicleInfo
        ref="vehicleInfo"
        v-if="flag.showVehicleInfo"
        :vehicleInfo="vehicleInfo"
      ></VehicleInfo>
    </el-dialog>
    <!-- 司机信息组件 -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.showDriverInfo"
      append-to-body
    >
      <DriverInfo :driverInfo="driverInfo" />
    </el-dialog>
  </div>
</template>

<script>
import { consignSixElement } from "@/api/waybill/waybillManage/index";
import {
  getMapPath,
  getCarInfo,
  getDriverDetails,
  carInfoByNum,
} from "@/api/common/common";
import { mapMutations, mapGetters } from "vuex";
import basicMixin from "@/mixins/basic";
import TXmap from "@/components/commonCmpt/TXmap";
import Step from "@/components/businessCmpt/step";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
export default {
  mixins: [basicMixin],
  data() {
    return {
      flag: {
        loadingTable: false, //是否正在加载表格
        showMap: false, //显示地图
        showVehicleInfo: false, //显示车辆信息
        showDriverInfo: false, //显示司机信息
        settlement: false, //结算申请中
        dialogLoading: false, //dialog弹窗是否是loading状态
      },
      //总运单
      pagination1: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      settlementInfo: {
        InvoiceTitleName: "",
        InvoiceTitleCreditCode: "",
        InvoiceTitleBankerName: "",
        InvoiceTitlePhone: "",
        InvoiceTitleAddress: "",
        InvoiceTitleBankerNumber: "",
      }, //结算信息
      tableDataTotal: [], //表格数据总量
      mapList: [], //地图列表
      vehicleInfo: {}, //车辆信息
      driverInfo: {}, //司机信息
      waybillBusinessType: null, //运单业务类型
      waybillBusinessTypeId: null, //运单业务类型id
      waybillRate: null, //运单发票费率
      waybillSceneType: null, //运单场景类型
      waybillSceneId: null, //运单场景id
    };
  },
  methods: {
    ...mapMutations(["setInvoiceTaskID"]),
    //获得业务类型和发票费率
    getWaybillBusinessType() {
      if (
        !this.getWaybillType.sceneId ||
        !this.getWaybillType.sceneType ||
        !this.getWaybillType.taskType ||
        !this.getWaybillType.rateType ||
        !this.getWaybillType.taskTypeId
      ) {
        this.$router.push("/waybill/waybillManage/businessChoose");
      }
      this.waybillSceneId = this.getWaybillType.sceneId;
      this.waybillSceneType = this.getWaybillType.sceneType;
      this.waybillBusinessType = this.getWaybillType.taskType;
      this.waybillBusinessTypeId = this.getWaybillType.taskTypeId;
      this.waybillRate = this.getWaybillType.rateType;
      this.tableDataTotal = this.getWaybillType.waybillList;
      this.pagination1.total = this.tableDataTotal.length;
    },
    //分页回调
    handleCurrentChange1(e) {
      this.pagination1.page = e;
    },
    //打开地图
    openMap(item) {
      this.flag.dialogLoading = true;
      let params = {
        wayBillId: item.WaybillNumber,
        searchWord: item.LicensePlate,
        stDate: item.Loading_time,
        edDate: item.Submission_time,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.dialogLoading = true;
      let params = {
        CarNumber: item.LicensePlate ? item.LicensePlate : item.CarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //查看司机信息
    openDerverInfo(item,UserID) {
      let data = {};
      getDriverDetails({ UserID: UserID, AscriptionUserID: item.UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.flag.showDriverInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //结算申请
    goStepTwo() {
      //申请的时候把运单传给后端
      this.$emit("setWaybillList", this.tableDataTotal);
      this.$emit("stepChange", 2);
    },
    //获取货主六要素
    consignSixElement() {
      consignSixElement().then((res) => {
        this.settlementInfo.InvoiceTitleName = res.SixElement.InvoiceTitleName;
        this.settlementInfo.InvoiceTitleCreditCode =
          res.SixElement.InvoiceTitleCreditCode;
        this.settlementInfo.InvoiceTitleBankerName =
          res.SixElement.InvoiceTitleBankerName;
        this.settlementInfo.InvoiceTitlePhone =
          res.SixElement.InvoiceTitlePhone;
        this.settlementInfo.InvoiceTitleAddress =
          res.SixElement.InvoiceTitleAddress;
        this.settlementInfo.InvoiceTitleBankerNumber =
          res.SixElement.InvoiceTitleBankerNumber;
      });
    },
  },
  created() {
    //清空开票单号
    this.setInvoiceTaskID("");
    //获得业务类型
    this.getWaybillBusinessType();
    //获取货主六要素
    this.consignSixElement();
  },
  computed: {
    ...mapGetters(["getWaybillType"]),
    tableData() {
      //表格数据
      return this.tableDataTotal.slice(
        this.pagination1.pagesize * (this.pagination1.page - 1),
        this.pagination1.pagesize * this.pagination1.page
      );
    },
    waybillListSum() {
      //运费
      let count = 0;
      if (this.$entrustSceneIds.includes(Number(this.getWaybillType.sceneId))) {
        this.tableDataTotal.forEach((item) => {
          count = count + Number(item.Entrusted_Collection_Amount);
        });
      } else {
        this.tableDataTotal.forEach((item) => {
          count = count + Number(item.amount_count);
        });
      }
      return count.toFixed(2);
    },
  },
  watch: {
    "flag.showMap"(val) {
      if (!val) {
        this.mapList = [];
      }
    },
  },
  components: {
    Step,
    TXmap,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../assets/style/variable.scss";

.supplierStepOneCmpt {
  .step {
    margin: 0 auto 16px;
  }

  .facilityBox {
    .tableData {
      .title {
        color: #2081ff;
        position: relative;
        text-indent: 15px;
        font-size: 14px;
        border-bottom: 1px solid $borderGray;
        padding: 8px 0;
        margin-bottom: 20px;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          border-radius: 5px;
          background: $primary;
        }
      }

      .settleBox {
        margin-bottom: 10px;
        padding: 10px 16px;
        background-color: #f0f0f0;
        color: #666;
      }
    }

    thead th {
      background: $primary !important;
    }
  }
}
</style>
